import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import Call from "../components/Call";

const Neurovitalis = (props) => {
  const { intro } = props.data;
  //   const introImageClasses = `intro-image ${intro.frontmatter.intro_image_absolute && "intro-image-absolute"} ${
  //     intro.frontmatter.intro_image_hide_on_mobile && "intro-image-hide-mobile"
  //   }`;

  return (
    <Layout bodyClass="page-neurovitalis">
      <SEO title="Neurovitalis" />

      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <div dangerouslySetInnerHTML={{ __html: intro.html }} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query NeurovitalisQuery {
    intro: markdownRemark(fileAbsolutePath: { regex: "/(neurovitalis.md)/" }) {
      html
      frontmatter {
        image
        intro_image
        intro_image_absolute
        intro_image_hide_on_mobile
        title
      }
    }
  }
`;

export default Neurovitalis;
